import React from "react";
import { Link } from "react-router-dom";

export default function MenuWidget({
	menuItems,
	menuHeading,
	variant,
	dataSource,
}) {
	return (
		<>
			{menuHeading && <h2 className="cs-widget_title">{menuHeading}</h2>}
			<ul
				className={`${
					variant
						? `cs-menu_widget ${variant}`
						: "cs-menu_widget cs-style1"
				} cs-mp0`}
			>
				{menuItems.map((item, index) => (
					<li key={index}>
						{item.href ? (
							<Link to={item.href} className="service_item">
								{item.title} <br />{" "}
								<p className="mt-2">{item.address}</p>
							</Link>
						) : (
							item.title
						)}
					</li>
				))}
			</ul>
		</>
	);
}
