import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Sidebar from "../Sidebar.jsx";
import Spacing from "../Spacing";
import ContactPage from "./ContactPage";
import copy from "clipboard-copy";
import { message } from "antd";
import { AiOutlineLink } from "react-icons/ai";

const BlogDetailPage5 = () => {
	const params = useParams();

	pageTitle("Blog Details");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleCopy = () => {
		copy("https://global-living-master.vercel.app/blog/4");
		message.success("Đã sao chép!");
	};

	return (
		<>
			<PageHeading
				title={
					<>
						Khám phá 10 điểm du lịch đẹp{" "}
						<AiOutlineLink
							className="mb-2 mx-3 text-[30px] cursor-pointer"
							onClick={handleCopy}
						/>
					</>
				}
				bgSrc="/images/blog_details_hero_bg.jpeg"
				pageLinkText={"Khám phá 10 điểm du lịch đẹp"}
			/>

			<Spacing lg="150" md="80" />
			<Div className="container">
				<Div className="row items-center justify-center">
					<Div className="col-lg-8">
						<Div className="cs-post cs-style2">
							<Div className="cs-post_info">
								<Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
									<span className="cs-posted_by">
										08/06/2023
									</span>
									{/* <Link to="/blog" className="cs-post_avatar">
										Tech
									</Link> */}
								</Div>
								<h2 className="cs-post_title">
									Khám phá 10 điểm du lịch đẹp đến nao lòng ở
									Budapest - Viên ngọc quý của dòng Danube
								</h2>
								<p>
									Khi nhắc đến du lịch Hungary, không thể bỏ
									qua những địa điểm nổi tiếng và đầy mê hoặc.
									Từ dòng sông quyến rũ Danube đến cầu Liberty
									kiêu sa, từ lâu đài Buda lịch sử đến thị
									trấn Spa thư giãn, những điểm đến này tựa
									như những viên ngọc quý trên bức tranh du
									lịch Hungary. Bài viết dưới đây sẽ đưa bạn
									vào hành trình khám phá những điểm đến độc
									đáo này và tìm hiểu tại sao chúng hấp dẫn và
									không thể bỏ qua khi bạn đặt chân đến
									Hungary.
								</p>
								<p className="font-bold">1. Sông Danube</p>

								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-1.jpg"
										alt="blog"
									/>
								</div>

								<p className="mb-3">
									Sông Danube - Hành trình thần tiên giữa
									Vienna và Budapest. Với vị trí thuận lợi
									giữa những ngọn đồi, sông Danube mang đến
									cho du khách khung cảnh thiên nhiên tuyệt
									đẹp không thể chối từ. Bước chân vào vùng
									này, bạn sẽ được khám phá pháo đài La Mã
									Kelen Maria và tìm hiểu những ngôi làng
									truyền thống đậm chất địa phương. Dạo chơi
									trên dòng sông này, từ Bắc chảy tới Nam và
									đi qua Budapest, bạn sẽ được chứng kiến
									những cảnh hoàng hôn tuyệt đẹp, tạo nên
									những kỷ niệm đáng nhớ trong hành trình khám
									phá Hungary.
								</p>

								<p className="font-bold">2. Cầu Liberty</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-2.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Cầu Liberty - Trải nghiệm kiến trúc tuyệt
									vời trên sông Danube. Dù là cây cầu ngắn
									nhất tại Budapest, nhưng Cầu Liberty lại sở
									hữu một kiến trúc độc đáo và không thể nhầm
									lẫn trên toàn cầu. Điều tuyệt vời nhất là
									vào sáng sớm, khi sương mù che phủ toàn bộ
									cây cầu, tạo nên một cảnh tượng như bức
									tranh thần tiên. Hãy dành thời gian để chiêm
									ngưỡng vẻ đẹp tuyệt đẹp này, khi mây sương
									và ánh nắng ban mai tạo nên một bầu không
									khí đầy mê hoặc và lãng mạn.
								</p>

								<p className="font-bold">3. Lâu đài Buda</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-3.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Nếu bạn đang tìm kiếm một điểm đến ấn tượng
									ở Hungary, thì Lâu đài Buda chính là lựa
									chọn hoàn hảo. Với kiến trúc nguy nga, tráng
									lệ, lâu đài này thu hút lượng lớn du khách
									bởi bảo tàng và phòng triển lãm nghệ thuật
									tuyệt vời. Được công nhận là di sản thế
									giới, Lâu đài Buda nằm ở vị trí độc đáo trên
									cầu Xích, tạo ra một cảnh quan tuyệt đẹp khi
									đèn đêm thắp sáng và phản chiếu xuống dòng
									sông Danube. Hãy dành thời gian để khám phá
									và chiêm ngưỡng vẻ đẹp đậm chất lịch sử và
									nghệ thuật tại điểm đến này khi bạn thăm
									Hungary.
								</p>

								<p className="font-bold">
									4. Thị trấn Spa lịch sử
								</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-4.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Điểm đến tiếp theo không thể bỏ qua khi du
									lịch Hungary chính là thị trấn Spa lịch sử.
									Đây là một điểm dừng chân lý tưởng để khám
									phá nền văn hóa độc đáo của đất nước. Ở
									Hungary mọi người đều tự hào với những phòng
									tắm và suối nước nóng rộng lớn, đã được xây
									dựng từ thời kỳ La Mã. Khi bạn ghé thăm thị
									trấn xinh đẹp này, bạn sẽ được trải nghiệm
									tắm nước nóng thư giãn và thưởng thức liệu
									trình spa tuyệt vời. Hãy dành thời gian để
									tận hưởng sự thư thái và khám phá những trải
									nghiệm độc đáo mà Spa Hungary mang đến cho
									bạn..
								</p>

								<p className="font-bold">5. Lâu đài Eger</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-5.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Lâu đài Eger, nằm tại thị trấn Spa và tọa
									lạc ở phía Nam của dãy núi Bükk, là một điểm
									tham quan không thể bỏ qua ở Hungary. Lâu
									đài này từng đóng vai trò quan trọng trong
									việc bảo vệ cửa ngõ miền Bắc của Hungary
									trong cuộc chiến chống người Thổ chiếm vào
									năm 1956. Bước vào lâu đài, du khách sẽ có
									cơ hội tìm hiểu về những tàn tích lịch sử và
									khám phá sâu hơn về văn hóa và lịch sử đặc
									trưng của Hungary. Đây là một trải nghiệm
									đặc biệt để khám phá và khám phá sự phát
									triển của đất nước này qua các thời kỳ lịch
									sử khác nhau.
								</p>

								<p className="font-bold">
									6. Nhà thờ St. Peter
								</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-6.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Nếu bạn đang tìm kiếm một địa điểm tham quan
									lịch sử đẹp ở Hungary, thì nhà thờ St. Peter
									là một lựa chọn lý tưởng. Nhà thờ này đã
									được thành lập vào năm 1009 và trải qua quá
									trình trùng tu vào cuối thế kỷ 12 trong cuộc
									chiến chống quân Mông Cổ. Bên trong nhà thờ,
									bạn sẽ có cơ hội ngắm nhìn rất nhiều hiện
									vật quý giá, đặc biệt là bảo tàng và ngọn
									tháp cao 22m. Đây là một địa điểm đáng khám
									phá, nơi bạn có thể khám phá và tìm hiểu về
									lịch sử và nghệ thuật tại Hungary từ thời kỳ
									xa xưa đến hiện đại.
								</p>

								<p className="font-bold">
									7. Công viên quốc gia Aggtelek và hang động
								</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-7.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Nếu bạn muốn tận hưởng không gian thiên
									nhiên trong lành ở Hungary, thì công viên
									quốc gia Aggtelek và các hang động huyền bí
									là điểm đến lý tưởng. Nơi đây tọa lạc nhiều
									hang động vôi hóa tuyệt đẹp. Với diện tích
									rộng lớn và được UNESCO công nhận là di sản
									thiên nhiên thế giới, công viên này là một
									kho báu thiên nhiên đáng khám phá. Đặc biệt,
									hang Baradla là điểm nhấn nổi bật với tổng
									chiều dài lên tới 25km và đường hầm dài 7km,
									nơi bạn có thể ngắm nhìn những khối nhũ đá
									khổng lồ và đầy màu sắc. Ngoài ra, còn có ba
									hang động nổi tiếng khác là Hang Imre Vass,
									Hang Béke và Hang Rákóczi. Hãy dành thời
									gian để khám phá những kỳ quan thiên nhiên
									tuyệt đẹp này và trải nghiệm cuộc sống động
									trong lòng đất Hungary.
								</p>

								<p className="font-bold">
									8. Cung điện Hoàng gia Visegrád
								</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-8.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Cung điện Hoàng gia Visegrád là một lựa chọn
									tuyệt vời cho một địa điểm đẹp và mang dấu
									ấn lịch sử. Cung điện này nằm trong một thị
									trấn cổ tại Visegrád, cách thủ đô Budapest
									khoảng 40km về hướng Bắc. Nó được xây dựng
									dưới thời của Charles I khi ông chuyển tới
									Visegrád vào năm 1316. Cung điện Hoàng gia
									Visegrád được đánh giá là một trong những
									cung điện đẹp nhất ở châu u, với kiến trúc
									tinh xảo và vẻ đẹp lộng lẫy, cung điện này
									mang đến một trải nghiệm lịch sử và văn hóa
									đáng nhớ. Hãy khám phá vẻ đẹp và sự tuyệt
									vời của Cung điện Hoàng gia Visegrád khi bạn
									thăm Hungary..
								</p>

								<p className="font-bold">9. Sopron</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-9.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Sopron là một trong những địa điểm du lịch
									nổi tiếng và hấp dẫn ở Hungary, thu hút du
									khách từ khắp nơi đổ về. Thành phố này nằm
									cách thủ đô Vienna khoảng 64km về phía Nam
									và gần với dãy núi Alps. Sopron được bao
									quanh bởi những tòa nhà cổ kính và di tích
									lịch sử nổi tiếng của Hungary. Nếu bạn có cơ
									hội ghé thăm đất nước Hungary tuyệt đẹp, hãy
									dành thời gian để khám phá địa điểm nổi
									tiếng này. Sopron sẽ mang đến cho bạn những
									trải nghiệm đáng nhớ với vẻ đẹp và sự lịch
									sử độc đáo của nó.
								</p>

								<p className="font-bold">10. Tihany</p>
								<div className="text-center my-4">
									<img
										src="/images/blog/blog5-10.jpg"
										alt="blog"
									/>
								</div>
								<p>
									Tihany là một khu nghỉ dưỡng nổi tiếng, ban
									đầu chỉ là một hòn đảo nhỏ bé, nhưng đã được
									đầu tư và trở thành điểm đến hàng đầu thu
									hút du khách tại Hungary. Tại khu nghỉ dưỡng
									này, du khách sẽ được tham quan Tu viện
									Benedictine với kiến trúc độc đáo, đồng thời
									chiêm ngưỡng vẻ đẹp của hồ nước tuyệt đẹp và
									cánh đồng hoa oải hương thơm ngát. Tihany
									hứa hẹn mang đến cho du khách những trải
									nghiệm nghỉ ngơi và thư giãn tuyệt vời trong
									một bầu không khí yên bình và tinh tế.
								</p>
								<p>
									Đó là những địa điểm du lịch tuyệt vời mà
									Hungary mang đến. Từ sự lộng lẫy của lâu đài
									Buda đến sự tươi đẹp của công viên quốc gia
									Aggtelek, từ Cung điện Hoàng gia Visegrád
									đến thành phố cổ Sopron, và từ khu nghỉ
									dưỡng Tihany đến những tuyệt tác kiến trúc
									của Budapest - Hungary sẽ làm cho chuyến du
									lịch của bạn trở thành một trải nghiệm đáng
									nhớ.
								</p>
								<p>
									Không phải ngẫu nhiên mà Budapest được mệnh
									danh là một trong những thành phố đẹp nhất
									Châu u và thu hút gần 50 triệu lượt du khách
									mỗi năm. Với thế mạnh du lịch vượt trội,
									tiềm năng kinh doanh - cho thuê Bất động sản
									tại Budapest nói riêng và đất nước Hungary
									nói chung đều theo đó mà phát triển mạnh mẽ,
									đưa quốc gia này trở thành một điểm đến đầu
									tư lý tưởng cho những nhà đầu tư sành sỏi.
								</p>
							</Div>
						</Div>
						{/* End Details Post Content */}

						{/* <ContactPage /> */}

						{/* Start Comment Section */}
						{/* <Spacing lg="30" md="30" />
						<h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
						<Spacing lg="5" md="5" />
						<p className="cs-m0">
							Your email address will not be published. Required
							fields are marked *
						</p>
						<Spacing lg="40" md="30" />
						<form className="row">
							<Div className="col-lg-6">
								<label>Full Name*</label>
								<input type="text" className="cs-form_field" />
								<Div className="cs-height_20 cs-height_lg_20" />
								<Div
									data-lastpass-icon-root="true"
									style={{
										position: "relative !important",
										height: "0px !important",
										width: "0px !important",
										float: "left !important",
									}}
								/>
							</Div>
							<Div className="col-lg-6">
								<label>Email*</label>
								<input type="text" className="cs-form_field" />
								<Div className="cs-height_20 cs-height_lg_20" />
							</Div>
							<Div className="col-lg-12">
								<label>Website*</label>
								<input type="text" className="cs-form_field" />
								<Div className="cs-height_20 cs-height_lg_20" />
							</Div>
							<Div className="col-lg-12">
								<label>Write Your Comment*</label>
								<textarea
									cols={30}
									rows={7}
									className="cs-form_field"
								/>
								<Div className="cs-height_25 cs-height_lg_25" />
							</Div>
							<Div className="col-lg-12">
								<button className="cs-btn cs-style1">
									<span>Send Message</span>
									<Icon icon="bi:arrow-right" />
								</button>
							</Div>
						</form> */}
						{/* End Comment Section */}
					</Div>
					{/* <Div className="col-xl-3 col-lg-4 offset-xl-1"> */}
					{/* Start Sidebar */}
					{/* <Spacing lg="0" md="80" /> */}
					{/* <Sidebar /> */}
					{/* End Sidebar */}
					{/* </Div> */}
				</Div>
			</Div>
			<Spacing lg="150" md="80" />
			{/* Start Blog Details */}

			{/* Start CTA Section */}
			<Div className="container">
				<Cta
					title="Let’s disscuse make <br />something <i>cool</i> together"
					btnText="Apply For Meeting"
					btnLink="/contact"
					bgSrc="/images/cta_bg.jpeg"
				/>
			</Div>
			{/* End CTA Section */}
		</>
	);
};

export default BlogDetailPage5;
