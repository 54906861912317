import React, { useState } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import Testimonial from "../Testimonial";
import Div from "../Div";
import Spacing from "../Spacing";
export default function TestimonialSlider({ dataSource }) {
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	const mapData = (data) => {
		if (!data || data?.length <= 0) return [];
		return data?.map((item) => {
			return {
				// ...item,
				testimonialThumb: item?.img,
				testimonialText: item?.comment,
				avatarName: item?.userComment,
				avatarDesignation: "Đầu tư BĐS tại Budapest",
				ratings: "4",
			};
		});
	};

	const testimonialData = [
		{
			testimonialThumb: "/images/member_2.jpeg",
			testimonialText:
				"Tôi thực sự ấn tượng với cơ hội đầu tư bất động sản tại Budapest, thủ đô của Hungary. Đây là một thành phố đầy cuốn hút, nổi tiếng với kiến trúc tuyệt đẹp, các công trình lịch sử và bảo tàng nổi tiếng. Bên cạnh đó, thành phố còn có một nền kinh tế phát triển mạnh mẽ và tiềm năng tăng trưởng lớn trong tương lai.",
			avatarName: "Chị Hà",
			avatarDesignation: "Đầu tư BĐS tại Budapest",
			ratings: "4",
		},
		{
			testimonialThumb: "/images/testimonial_2.jpeg",
			testimonialText:
				"Đầu tư bất động sản tại Budapest không chỉ mang lại cơ hội sinh lời hấp dẫn từ việc cho thuê và tăng giá trị tài sản, mà còn giúp nhà đầu tư tiếp cận với thị trường châu Âu, mở rộng quan hệ kinh doanh và tận hưởng lợi ích từ chính sách ưu đãi của Chính phủ Hungary.",
			avatarName: "Anh Hoàng",
			avatarDesignation: "Đầu tư BĐS tại Budapest",
			ratings: "5",
		},
		{
			testimonialThumb: "/images/testimonial_3.jpeg",
			testimonialText:
				"Đầu tư bất động sản tại Budapest còn giúp tôi trải nghiệm cuộc sống văn hóa đặc sắc của đất nước Hungary, từ ẩm thực đến âm nhạc, từ nghệ thuật đến lịch sử. Đặc biệt, việc sở hữu bất động sản tại đây còn giúp tôi cảm nhận được sự tự hào và hạnh phúc khi đóng góp vào sự phát triển của một thành phố đầy tiềm năng như Budapest.",
			avatarName: "Anh Trần Hải",
			avatarDesignation: "Đầu tư BĐS tại Budapest",
			ratings: "4.5",
		},
		{
			testimonialThumb: "/images/member_4.jpeg",
			testimonialText:
				"Đầu tư bất động sản tại Budapest là một quyết định khôn ngoan, mang lại cơ hội sinh lời và trải nghiệm đáng nhớ cho nhà đầu tư. Tôi hoàn toàn tin tưởng rằng, với những lợi thế nổi bật, Budapest sẽ tiếp tục thu hút đầu tư và phát triển mạnh mẽ trong những năm tới.",
			avatarName: "Chị Ngọc",
			avatarDesignation: "Đầu tư BĐS tại Budapest",
			ratings: "5",
		},
	];
	const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
		<div
			{...props}
			className={
				"slick-prev slick-arrow" +
				(currentSlide === 0 ? " slick-disabled" : "")
			}
			aria-hidden="true"
			aria-disabled={currentSlide === 0 ? true : false}
		>
			<Icon icon="bi:arrow-left" />
		</div>
	);
	const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
		<div
			{...props}
			className={
				"slick-next slick-arrow" +
				(currentSlide === slideCount - 1 ? " slick-disabled" : "")
			}
			aria-hidden="true"
			aria-disabled={currentSlide === slideCount - 1 ? true : false}
		>
			<Icon icon="bi:arrow-right" />
		</div>
	);
	return (
		<>
			<Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
				<Spacing lg="130" md="80" />
				<Div className="cs-shape_3 cs-to_up">
					<img src="/images/shape_1.svg" alt="Shape" />
				</Div>
				<Div className="container">
					<Div className="cs-testimonial_slider">
						<Div className="cs-testimonial_slider_left">
							<Slider
								asNavFor={nav1}
								ref={(slider2) => setNav2(slider2)}
								slidesToShow={3}
								swipeToSlide={true}
								focusOnSelect={true}
								centerMode={true}
								centerPadding="0px"
								arrows={false}
							>
								{(dataSource?.length > 0
									? mapData(dataSource)
									: testimonialData
								)?.map((item, index) => (
									<Div
										className="slider-nav_item"
										key={index}
									>
										<Div className="cs-rotate_img">
											<Div className="cs-rotate_img_in">
												<img
													src={item.testimonialThumb}
													alt="Thumb"
												/>
											</Div>
										</Div>
									</Div>
								))}
							</Slider>
						</Div>
						<Div className="cs-testimonial_slider_right">
							<Slider
								asNavFor={nav2}
								ref={(slider1) => setNav1(slider1)}
								prevArrow={<SlickArrowLeft />}
								nextArrow={<SlickArrowRight />}
								className="cs-arrow_style1"
							>
								{(dataSource?.length > 0
									? mapData(dataSource)
									: testimonialData
								)?.map((item, index) => (
									<Div key={index}>
										<Testimonial
											testimonialText={
												item.testimonialText
											}
											avatarName={item.avatarName}
											avatarDesignation={
												item.avatarDesignation
											}
											ratings={item.ratings}
										/>
									</Div>
								))}
							</Slider>
						</Div>
					</Div>
				</Div>
				<Spacing lg="130" md="80" />
			</Div>
		</>
	);
}
