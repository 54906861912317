export const columnsInvest = [
	{
		dataIndex: "heading",
		title: "Tiêu đề",
	},
	// {
	// 	dataIndex: "img",
	// 	title: "Ảnh",
	// },
	{
		dataIndex: "action",
		width: "10%",
	},
];
