export const columns = [
	{
		dataIndex: "full_name",
		title: "Họ và tên",
	},
	{
		dataIndex: "email",
		title: "Email",
	},
	{
		dataIndex: "product",
		title: "Sản phẩm",
	},
	{
		dataIndex: "phone",
		title: "Số điện thoại",
	},
	{ dataIndex: "action", width: "10%" },
];
