export const columnsOverview = [
	{
		dataIndex: "heading",
		title: "Tiêu đề",
	},
	{
		dataIndex: "detail",
		title: "Nội dung",
		render: (text) => (
			<p
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			></p>
		),
	},
	{
		dataIndex: "number",
		title: "Chi phí làm thẻ",
	},
	{
		dataIndex: "descriptionNumber",
		title: "Tháng",
	},
	{
		dataIndex: "invest",
		title: "Chi phí đầu tư tối thiểu",
	},
	{
		dataIndex: "action",
		width: "10%",
	},
];
