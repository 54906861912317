export const columns = [
	{
		dataIndex: "question",
		title: "Câu hỏi",
	},
	{
		dataIndex: "answer",
		title: "Trả lời",
	},
	{
		dataIndex: "action",
		width: "10%",
	},
];
