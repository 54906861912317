export const columns = [
	{
		dataIndex: "title",
		title: "Tiêu đề",
	},
	{
		dataIndex: "img",
		title: "Ảnh",
	},
	// {
	// 	dataIndex: "content",
	// 	title: "Nội dung",
	// render: (text) => (
	// 	<p
	// dangerouslySetInnerHTML={{
	// 	__html: text,
	// }}
	// 	></p>
	// ),
	// },
	// {
	// 	dataIndex: "img",
	// 	title: "Ảnh",
	// 	render: (text) => (
	// 		<img src={text} alt="image" style={{ width: "100px" }} />
	// 	),
	// 	width: "300px",
	// },
	{
		dataIndex: "action",
		width: "10%",
	},
];
