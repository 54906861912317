export const columns = [
	{
		dataIndex: "category",
		title: "Danh mục",
	},
	{
		dataIndex: "action",
		width: "10%",
	},
];
