export const columnsComment = [
	{
		dataIndex: "userComment",
		title: "Người bình luận",
	},
	{
		dataIndex: "comment",
		title: "Bình luận",
	},
	{
		dataIndex: "action",
		width: "10%",
	},
];
